<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <div v-if="loading" class="mb-0 text-center">Authenticating...</div>
                        <p v-else-if="errMsg" class="mb-0 mt-0 text-danger text-center text-sm">{{ errMsg }}</p>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'tokenLogin',
    data() {
      return {
        loading: true,
        errMsg: null
      }
    },
    mounted () {
      const token = this.$route.query.token
      if(!token) {
        this.$router.replace('/login')
        return
      }
      this.loginWithToken(token)
    },
    methods: {
      loginWithToken (token) {
        this.errMsg = null

        this.$store.dispatch('auth/login', { username: '', password: '', token })
          .then(() => {
            this.$router.go()
            this.loading = false
          })
          .catch((error) => {
            this.errMsg = error?.response?.data?.error?.description || 'Error while trying to log you in. Please try again.'
            this.loading = false
          })
      }
    }
  }
</script>
<style>
</style>
