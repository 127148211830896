<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Email"
                                        addon-left-icon="ni ni-circle-08"
                                        v-model="model.username">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Passwort"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password">
                            </base-input>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click="handleSubmit">Login</base-button>
                            </div>
                        </form>
                        <p v-if="errMsg" class="mb-0 mt-0 text-danger text-center text-sm">{{ errMsg }}</p>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'login',
    data() {
      return {
        model: {
          username: '',
          password: ''
        },
        errMsg: null
      }
    },
    methods: {
      handleSubmit () {
        this.errMsg = null

        if (!this.model.username || !this.model.password) {
          this.errMsg = 'Bitte geben Sie Benutzername und Passwort ein'
          return
        }

        this.$store.dispatch('auth/login', this.model)
          .then(() => {
            this.$router.go()
          })
          .catch((error) => {
            this.errMsg = error?.response?.data?.error?.description || 'Error while trying to log you in. Please try again'
          })
      }
    }
  }
</script>
<style>
</style>
