<template>
  <div>
    <!-- <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
    </base-header> -->
    <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card title="Speicherplatz" type="gradient-info" :sub-title="getSubtitle('diskspace')" subtitle-classes="h3" icon="ni ni-chart-pie-35" class="mb-4 mb-xl-0" />
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState('server', [
      'serverStatus'
    ])
  },
  mounted() {
    this.fetchServerStatus()
  },
  methods: {
    fetchServerStatus () {
      return this.$store.dispatch('server/fetchServerStatus')
    },
    getSubtitle (key) {
      if (this.serverStatus) {
        switch (key) {
          case 'diskspace':
            if (this.serverStatus.diskSpace) {
              return `${this.humanFileSize(this.serverStatus.diskSpace.available)} von ${this.humanFileSize(this.serverStatus.diskSpace.total)} frei zur Verwendung`
            }
            break
        }
      }
      return '...'
    },
    humanFileSize (bytes, si=false, dp=1) {
      const thresh = si ? 1000 : 1024
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }
      const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10**dp
      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
      return bytes.toFixed(dp) + ' ' + units[u]
    }
  }
};
</script>
